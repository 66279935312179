import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";

import Accordion from "../components/accordion";
import EngageBanner from "../components/box-engage-banner";
import BannerTickets from "../components/banner-tickets";
import BoxIniziativa from "../components/box-iniziativa";
import BoxSponsor from "../components/box-sponsor";
import BoxHelp from "../components/box-help";
import BoxNewsletter from "../components/box-newsletter";
import BoxArchivio from "../components/box-archivio";

const EdizioniPassate = ({ data }) => {
  const { editions, archivio } = data;

  return (
    <Layout>
      <Seo
        title={`Edizioni passate IIC & IID`}
        description={`Guarda le edizioni passate di Intranet Italia Champions & Intranet Italia Day`}
      />

      <div className="box-intro">
        <div className="animation-intro-wrap">
          <video
            width="1440px"
            height="720px"
            autoPlay
            muted
            loop
            playsInline
            className="animation-intro"
          >
            <source src="/assets/iid-iic.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="box-intro__text">
          <div className="container-md">
            <div className="row">
              <div className="col-12 text-center">
                <div className="box-intro__claim">
                  <p>Scopri i contenuti delle scorse edizioni</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="iid" className="section section--grey-dark">
        <div className="box-article">
          <div className="container-md">
            <div className="row">
              <div className="col-12 col-xl-8 offset-xl-1">
                <h2 className="box-article__title">IID: un'iniziativa di valore, nata nel 2018</h2>
                <div className="box-article__text">
                  <p>
                    Siamo arrivati alla quinta edizione della manifestazione organizzata da Intranet
                    Management e Ariadne Group. In questa sezione troverai lo storico delle scorse
                    edizioni, con tutte le informazioni sui temi e i contenuti trattati negli anni.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="section__title">
                  <span>Archivio scorse</span> edizioni
                </div>
              </div>
            </div>
          </div>
        </div>

        <BoxArchivio items={archivio.archivio} />
      </section>

      <section id="iic" className="section section--grey-dark">
        <div className="box-article">
          <div className="container-md">
            <div className="row">
              <div className="col-12 col-xl-8 offset-xl-1">
                <h2 className="box-article__title">
                  IIC: il premio per le migliori intranet italiane
                </h2>
                <div className="box-article__text">
                  <p>Scopri i progetti vincitori delle scorse edizioni</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Accordion items={editions.nodes?.[0].accordionItems} />

        {/* <div className="container-md pb-5">
          <div className="row">
            <div className="col-12 text-center">
              <Link to="/scarica-report-iic/" className="btn-link btn-link--download">
                <span className="btn-link__circle"></span>
                <span className="btn-link__text">
                  Scarica il <span className="d-block bold">report</span>
                </span>
              </Link>
            </div>
          </div>
        </div> */}
      </section>

      <EngageBanner />

      <BannerTickets />

      <BoxIniziativa />

      <BoxSponsor />

      <BoxHelp />

      <BoxNewsletter />
    </Layout>
  );
};

export default EdizioniPassate;

export const query = graphql`
  query {
    editions: allContentfulAccordionPage(filter: { entryTitle: { eq: "Editions" } }) {
      nodes {
        accordionItems {
          title
          text {
            childMarkdownRemark {
              html
            }
          }
          year
          winners {
            title
            image {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
            claim
          }
        }
      }
    }
    archivio: archivioJson {
      archivio {
        title
        year
        link
      }
    }
  }
`;
